import React from 'react'
import Cookies from 'js-cookie';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Header() {

    const userN = Cookies.get('userName')
    const UserId = Cookies.get('id');


    const handleLogout = async () => {
        try {
            const res = await fetch(`https://resultapi.s2bet.in/api/resultpanel/auth/logout`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ UserId })
            })

            const response = await res.json();

            if (response.isSuccess) {
                // Navigate('/');
                window.location.href = '/'
                Cookies.remove('userName');
                Cookies.remove('id');
                Cookies.remove('fullName');
                Cookies.remove('RoleId');
                Cookies.set('isLoggedIn', false)
                toast.success(response.message, {
                    autoClose: 1000,
                    theme: "colored",
                });
            } else {
                toast.error(response.message, {
                    autoClose: 1000,
                    theme: "colored",
                });
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }


    }

    return (
        <>
            <div className="main-header login-header">
                <nav className="navbar navbar-light">
                    <div className="container mxw-100">
                        <div className="left-logo d-flex align-items-center">
                            <a className="navbar-brand" href="/">
                                <img src="uploads/panel-logo.png" />
                            </a>
                        </div>
                        <div className="right-side-inputs">
                            <form className="d-flex desktop-header-login ng-untouched ng-pristine ng-valid">
                                <ul className="header-user">
                                    <li>
                                        {userN}
                                    </li>
                                </ul>
                                <ul className="amout-dtls bg-black1">
                                    <li>
                                        <a href="javascript:void(0);" className="load-btn">
                                            <i className="fa fa-history" aria-hidden="true" />
                                        </a>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </nav>
                <nav>
                    <div className="navmenu">
                        <div className="container mxw-100">
                            <div className="d-flex justify-content-between">
                                <ul className="nav nav-pills flex-nowrap">
                                    <li className="nav-item">
                                        <Link to={"/"} className="nav-link active">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/matchResult"} className="nav-link">
                                            Match Result
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/fancyResult"} className="nav-link">
                                            Fancy Result
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={"/completeMatch"}
                                        >
                                            Complete Match
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={"/completeFancy"}
                                        >
                                            Complete Fancy
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link"
                                            to={"/changePassword"}
                                        >
                                            Change Password
                                        </Link>
                                    </li>

                                </ul>
                                <ul className="right-setting-bet list-unstyled d-inline-flex">
                                    <li>
                                        <div className="btn-group setting-popup">
                                            <a href="javascript:void(0);">
                                                <span className="timer-zone">Time Zone&nbsp;</span>:&nbsp;{" "}
                                                <b>GMT+5:30</b>
                                            </a>
                                            <Link onClick={handleLogout}>
                                                <b>Logout</b>
                                                <i className="fa fa-sign-out ml-1" aria-hidden="true" />
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="download-list">
                    <div className="container mxw-100">
                        <div className="news-box align-items-center">
                            <div className="news-title">
                                <i className="fa fa-microphone ml-1 me-1" aria-hidden="true" /> News
                            </div>
                            <div className="news-content">
                                <marquee> Welcome to Result Panel 2023-24 </marquee>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header