import React, { useState } from 'react'
import Header from './Header'
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

function ChangePassword() {

    const userId = Cookies.get('id');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');

    const changePassWord = async () => {
        try {
            // Validate that new password and retype password match
            if (newPassword !== retypePassword) {
                toast.error(`New Passwords does not match!`, {
                    autoClose: 1500,
                    theme: "colored",
                });
                return;
            }

            const response = await fetch('https://resultapi.s2bet.in/api/resultpanel/auth/changePassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    UserId: parseInt(userId),
                    OldPassword: oldPassword,
                    NewPassword: newPassword,
                }),
            });

            const result = await response.json();

            if (result.isSuccess) {
                toast.success(result.message, {
                    autoClose: 1500,
                    theme: "colored",
                });
                setOldPassword('');
                setNewPassword('');
                setRetypePassword('');
            } else {
                toast.error(result.message, {
                    autoClose: 1500,
                    theme: "colored",
                });
            }
        } catch (error) {
            console.error('Error changing password:', error);
        }
    };

    const handleCancel = () => {
        setOldPassword('');
        setNewPassword('');
        setRetypePassword('');
    }



    return (
        <>
            <main role="main" className="pb-3">
                <div className="inner-pages banking">
                    <div className="container-fluid">
                        <div className="page-title" style={{ fontSize: 16 }}></div>
                        <div className="modal-dialog match-setting-model">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 id="AddAdminLabel" className="modal-title">
                                        Change Password
                                    </h6>
                                </div>
                                <div className="modal-body">
                                    <form noValidate="" className="">
                                        <div className="row">
                                            <div className="col-sm-12 pe-2">
                                                <div className="form-group">
                                                    <label htmlFor="scorekey">Old Password:</label>
                                                    <input
                                                        type="password"
                                                        id="scorekey"
                                                        formcontrolname="score_key"
                                                        className="form-control"
                                                        required="required"
                                                        value={oldPassword}
                                                        onChange={(e) => setOldPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 pe-2">
                                                <div className="form-group">
                                                    <label htmlFor="scorekey">New Password:</label>
                                                    <input
                                                        type="password"
                                                        id="scorekey"
                                                        formcontrolname="matchMax"
                                                        className="form-control"
                                                        required="required"
                                                        value={newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 pe-2">
                                                <div className="form-group">
                                                    <label htmlFor="scorekey">Confirm Password:</label>
                                                    <input
                                                        type="password"
                                                        id="scorekey"
                                                        formcontrolname="matchMin"
                                                        className="form-control"
                                                        required="required"
                                                        value={retypePassword}
                                                        onChange={(e) => setRetypePassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="modal-footer">
                                        <button data-dismiss="modal" className="btn btn-danger" onClick={handleCancel}>
                                            Cancel
                                        </button>
                                        <button className="btn btn-success" onClick={changePassWord}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-section mt-3 unmatech-section"></div>
                </div>
            </main>

        </>

    )
}

export default ChangePassword