import React from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie';

function Dashboard() {

    const loggedInRoleId = Cookies.get('RoleId')
    console.log('LoggedIn Role Id : ', loggedInRoleId)
    return (
        <>
            <main role="main" className="pb-3">
                <div className="inner-pages banking">
                    <div className="container-fluid">
                        <div className="page-title" style={{ fontSize: 16 }}></div>
                        <div className="modal-dialog match-setting-model">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 style={{ textAlign: "center" }}>Result Panel</h6>
                                </div>
                                <div className="modal-body">
                                    <form noValidate="" className="">
                                        <div className="row">
                                            <div className="col-sm-6 pe-2" style={{ paddingLeft: 49 }}>
                                                <Link to={"/matchResult"}>
                                                    <button
                                                        className="btn btn-dark"
                                                        style={{ height: 110, width: 150, marginBottom: 10 }}
                                                    >
                                                        Match Result
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="col-sm-6 pe-2" style={{ paddingLeft: 49 }}>
                                                <Link to={"/fancyResult"}>
                                                    <button
                                                        className="btn btn-dark"
                                                        style={{ height: 110, width: 150, marginBottom: 10 }}
                                                    >
                                                        Fancy Result
                                                    </button>
                                                </Link>
                                            </div>
                                            <div />
                                            <div />
                                            <div />
                                            <div className="col-sm-6 pe-2" style={{ paddingLeft: 49, display: loggedInRoleId == 1 ? 'block' : 'none' }}>
                                                <Link to={'/managerList'}>
                                                    <button
                                                        className="btn btn-dark"
                                                        style={{ height: 110, width: 150, marginBottom: 10 }}
                                                    >
                                                        Manager List
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="col-sm-6 pe-2" style={{ paddingLeft: 49, display: loggedInRoleId == 1 ? 'block' : 'none' }}>
                                                <Link to={"/addManager"}>
                                                    <button
                                                        className="btn btn-dark"
                                                        style={{ height: 110, width: 150, marginBottom: 10 }}
                                                    >
                                                        Add Manager
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>


    )
}

export default Dashboard