import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoutes() {

    const [authentication, setAuthentication] = useState(true);

    useEffect(() => {
        const isLoggedIn = Cookies.get('isLoggedIn');
        console.log('is logged in console ... ', isLoggedIn);
        console.log('type of logged in console ... ', typeof isLoggedIn);

        if (isLoggedIn == 'true') {
            setAuthentication(true);
        } else {
            setAuthentication(false);
        }

    }, [])


    return (
        authentication ? <Outlet /> : <Navigate to='/login' />
    )
}

export default PrivateRoutes