import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import 'moment-timezone';
import { toast } from 'react-toastify';

function CompleteFancy() {

    const [allData, setAllData] = useState([])
    const [fancyList, setFancyList] = useState([])
    const [sportSelect, setSportSelect] = useState(4)
    const [matchSelectList, setMatchSelectList] = useState([])
    const [isLoading, setIsLoading] = useState(false);


    const fetchFancy = async () => {

        try {
            setIsLoading(true);
            const res = await fetch(`https://resultapi.s2bet.in/api/resultPanel/fetchFancyCloselist`)
            const resData = await res.json()

            if (resData.isSuccess) {
                console.log("Fency data is : ", resData.data)
                setAllData(resData.data)
                setFancyList(resData.data)



                // Filter the data to include only unique eventId
                const uniqueEventData = resData.data.reduce((acc, current) => {
                    const eventId = current.eventId;
                    if (!acc[eventId]) {
                        acc[eventId] = current;
                    }
                    return acc;
                }, {});

                // Convert the object back to an array
                const uniqueFancyList = Object.values(uniqueEventData);
                setMatchSelectList(uniqueFancyList);

                if (sportSelect == 0 || sportSelect == 4) {
                    setFancyList(resData.data)

                } else {
                    setFancyList([]);
                }
            }
        } catch (error) {
            console.log('Error while fetching fancy close list...', error)
        } finally {
            setIsLoading(false);
        }

    }


    const handleSportChange = (e) => {
        console.log('select sport is : ', e.target.value)
        setSportSelect(e.target.value)
    }


    const handleMatchSelect = (e) => {
        console.log('Match Select is : ', e.target.value)
        // setMatchSelect(e.target.value)

        if (e.target.value != undefined || e.target.value != 0) {

            const filterMatch = allData.filter(item => item.eventId == e.target.value)
            setFancyList(filterMatch)
        }
        if (e.target.value == undefined || e.target.value == 0) {
            setFancyList(allData)
        }
    }



    useEffect(() => {
        fetchFancy();
    }, [])


    return (
        <>
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        "\n     .success-row {\n    transition: background-color 0.5s ease;\n    background-color: #f8d7da;\n} \n  "
                }}
            />
            {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
            <main role="main" className="pb-3">
                <div className="inner-pages banking">
                    <div className="container-fluid">
                        <div className="page-title" style={{ fontSize: 16 }}>
                            <h6 className="my-2">Fancy Result Update</h6>
                        </div>
                        <form noValidate="" className="ng-untouched ng-pristine ng-valid mb-3">
                            <div className="row marketdiv">
                                <div className="col-lg-4">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Sports Name</label>
                                            <select
                                                id="sportOption"
                                                name="sportId"
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                                onChange={handleSportChange}
                                            >
                                                <option value={4}>All</option>
                                                {/* <option value={1} className="ng-star-inserted">
                                                    Soccer
                                                </option>
                                                <option value={2} className="ng-star-inserted">
                                                    Tennis
                                                </option>
                                                <option value={3} className="ng-star-inserted">
                                                    SBO RNG Games
                                                </option> */}
                                                <option value={4} className="ng-star-inserted" disabled>
                                                    Cricket
                                                </option>
                                                {/* <option value={5} className="ng-star-inserted">
                                                    Virtual Sports
                                                </option>
                                                <option value={7} className="ng-star-inserted">
                                                    Casino
                                                </option>
                                                <option value={9} className="ng-star-inserted">
                                                    Seamless Game Provider
                                                </option>
                                                <option value={85} className="ng-star-inserted">
                                                    Election
                                                </option> */}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Match Name</label>
                                            <select
                                                name="seriesId"
                                                id="seriesId"
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                                onChange={handleMatchSelect}
                                            >
                                                <option value={0}>All</option>
                                                {matchSelectList.length > 0 && matchSelectList.map((item, index) => (
                                                    <option value={item.eventId} key={index}>{item.eventName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Match Name</label>
                                            <select
                                                name="matchId"
                                                id="matchId"
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                            >
                                                <option value={0}>All</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </form>
                        <div className="profile-section mt-3 unmatech-section market-setting">
                            <div id="fancyList">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>SERIAL NO.</th>
                                            <th>MATCH NAME</th>
                                            <th>MATCH DATE</th>
                                            <th>RUNNERNAME</th>
                                            <th>RESULT</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fancyList.length > 0 && fancyList.map((item, index) => (

                                            <tr className={item.status == 'Close' ? "ng-star-inserted success-row" : "ng-star-inserted"} key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.eventName}</td>
                                                <td><Moment format='D/MM/Y HH:mm:ss'>{item.eventDate}</Moment></td>
                                                <td>{item.runnerName}</td>
                                                <td className="formwidth">
                                                    {item.result}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* <pagination-controls className="text-center">
                            <pagination-template>
                                <nav role="navigation" aria-label="Pagination">
                                    <ul className="ngx-pagination ng-star-inserted">
                                        <li className="pagination-previous disabled ng-star-inserted">
                                            <span aria-disabled="true" className="ng-star-inserted">
                                                Previous <span className="show-for-sr">page</span>
                                            </span>
                                        </li>
                                        <li className="current ng-star-inserted">
                                            <span aria-live="polite" className="ng-star-inserted">
                                                <span className="show-for-sr">You're on page </span>
                                                <span>1</span>
                                            </span>
                                        </li>
                                        <li className="ng-star-inserted">
                                            <a
                                                tabIndex={1}
                                                onclick="getSubUsersList(1,true,null)"
                                                className="ng-star-inserted"
                                            >
                                                <span className="show-for-sr">You're on page </span>
                                                <span>2</span>
                                            </a>
                                        </li>
                                        <li className="ng-star-inserted">
                                            <a
                                                tabIndex={2}
                                                onclick="getSubUsersList(2,true,null)"
                                                className="ng-star-inserted"
                                            >
                                                <span className="show-for-sr">You're on page </span>
                                                <span>3</span>
                                            </a>
                                        </li>
                                        <li className="pagination-next  ng-star-inserted">
                                            <a
                                                tabIndex={1}
                                                onclick="getSubUsersList(1,true,null)"
                                                className="ng-star-inserted"
                                            >
                                                Next <span className="show-for-sr">page</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </pagination-template>
                        </pagination-controls> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default CompleteFancy