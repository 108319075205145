import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import Header from './Header';
import Moment from 'react-moment';
import 'moment-timezone';
import Cookies from 'js-cookie';

function CompletedMatch() {

    const userN = Cookies.get('userName');


    const [eventRunners, setEventRunner] = useState([]);
    const [allData, setAllData] = useState([])
    const [selectedWinnerMO, setSelectedWinnerMO] = useState("");
    const [selectedWinnerBM, setSelectedWinnerBM] = useState("");
    const [successfulResponses, setSuccessfulResponses] = useState([]);
    const [sportSelect, setSportSelect] = useState(0)
    const [matchSelectList, setMatchSelectList] = useState([])
    const [matchSelect, setMatchSelect] = useState(0);
    const [isLoading, setIsLoading] = useState(false);


    // Fetch data for tables and initialize tablesData state
    const fetchData = async () => {
        try {
            setIsLoading(true);
            const winnerList = await fetch(`https://resultapi.s2bet.in/api/resultPanel/getWinnerList1`)
            const winnerData = await winnerList.json()


            console.log("winner list is : ", winnerData.data)

            if (winnerData.data.length > 0) {
                setAllData(winnerData.data)

                if (sportSelect != 0) {
                    const filterBySport = winnerData.data.filter(item => item.sportId == sportSelect)
                    setEventRunner(filterBySport);
                    const filterEventName = [...new Set(filterBySport.map(item => item.eventName))];
                    setMatchSelectList(filterEventName);

                    console.log("Series name is : ", filterEventName)

                } else {
                    setEventRunner(winnerData.data);
                    const filterEventName = [...new Set(winnerData.data.map(item => item.eventName))];
                    setMatchSelectList(filterEventName);
                }
            }

        } catch (err) {
            console.error("Error in fetching Inplay api .. ", err)
        } finally {
            setIsLoading(false);
        }
    };


    const handleSportChange = (e) => {
        console.log('select sport is : ', e.target.value)
        setSportSelect(e.target.value)
    }

    const handleMatchSelect = (e) => {
        console.log('Match Select is : ', e.target.value)
        setMatchSelect(e.target.value)

        if (e.target.value !== undefined || e.target.value !== 0) {

            const filterMatch = allData.filter(item => item.eventName == e.target.value)
            setEventRunner(filterMatch)
        }

        if (e.target.value == undefined || e.target.value == 0) {

            setEventRunner(allData)
        }

        // const filterByMatch = fancyList.filter
    }



    useEffect(() => {
        fetchData();
    }, [sportSelect]);

    return (
        <>

            <style
                dangerouslySetInnerHTML={{
                    __html:
                        "\n     .success-row {\n    transition: background-color 0.5s ease;\n    background-color: #f8d7da;\n} \n  "
                }}
            />
            {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
            <main role="main" className="pb-3">

                <div className="inner-pages banking">
                    <div className="container-fluid">
                        <div className="page-title" style={{ fontSize: 16 }}>
                            <h6 className="my-2">Match Result Update</h6>
                        </div>
                        <form noValidate="" className="ng-untouched ng-pristine ng-valid mb-3">
                            <div className="row marketdiv">
                                <div className="col-lg-3">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Sport Name</label>
                                            <select
                                                id="sportOption"
                                                name="sportId"
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                                onChange={handleSportChange}
                                            >
                                                <option value={0}>All</option>
                                                <option value={1} className="ng-star-inserted">
                                                    Soccer
                                                </option>
                                                <option value={2} className="ng-star-inserted">
                                                    Tennis
                                                </option>
                                                {/* <option value={3} className="ng-star-inserted">
                                                    SBO RNG Games
                                                </option> */}
                                                <option value={4} className="ng-star-inserted">
                                                    Cricket
                                                </option>
                                                {/* <option value={5} className="ng-star-inserted">
                                                    Virtual Sports
                                                </option>
                                                <option value={7} className="ng-star-inserted">
                                                    Casino
                                                </option>
                                                <option value={9} className="ng-star-inserted">
                                                    Seamless Game Provider
                                                </option>
                                                <option value={85} className="ng-star-inserted">
                                                    Election
                                                </option> */}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Series Name</label>
                                            <select
                                                name="seriesId"
                                                id="seriesId"
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                            >
                                                <option value={0}>All</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Match Name</label>
                                            <select
                                                name="matchId"
                                                id="matchId"
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                                onChange={handleMatchSelect}
                                            >
                                                <option value={0}>All</option>
                                                {matchSelectList.length > 0 && matchSelectList.map((item, index) => (
                                                    <option value={item} key={index}>{item}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* <div class="text-end mt-2"> */}
                        {/* <button type="submit" class="btn btnblk btn-sm w-auto ms-1">Auto Result Declare</button> */}
                        {/* </div> */}
                        <div className="profile-section mt-3 unmatech-section market-setting">
                            <div id="marketList">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>Match Name</th>
                                            <th>Series Name</th>
                                            <th>Match Date</th>
                                            <th>Sport Name</th>
                                            <th>Event Id</th>
                                            <th className="text-end">Match Odds</th>
                                            <th className="text-end">BookMaker</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {eventRunners.length > 0 && eventRunners.map((item, index) => {
                                            return (
                                                <tr className='success-row'
                                                    key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.eventName}</td>
                                                    <td>-</td>
                                                    <td><Moment format='D/MM/Y HH:mm:ss'>{item.resultDate}</Moment></td>
                                                    {/* Sport Name */}
                                                    <td className="form-to">
                                                        <span className="form">{(item.sportId || item.eid) == 1 ? "Soccer" : (item.sportId || item.eid) == 2 ? "Tennis" : (item.sportId || item.eid) == 4 ? "Cricket" : (item.sportId || item.eid)}</span>
                                                    </td>
                                                    <td>{item.gameId || item.eventId}</td>
                                                    {/* MatchOdds Select */}
                                                    <td className="text-end">
                                                        {item.mWinner != 'disabled' || '' ? item.mWinner : ''}
                                                    </td>
                                                    {/* BookMaker Select */}
                                                    <td className="text-end">
                                                        {item.bWinner != 'disabled' || '' ? item.bWinner : ''}
                                                    </td>
                                                    {/* Buttton */}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {/* <pagination-controls className="text-center">
                            <pagination-template>
                                <nav role="navigation" aria-label="Pagination">
                                    <ul className="ngx-pagination ng-star-inserted">
                                        <li className="pagination-previous disabled ng-star-inserted">
                                            <span aria-disabled="true" className="ng-star-inserted">
                                                Previous <span className="show-for-sr">page</span>
                                            </span>
                                        </li>
                                        <li className="current ng-star-inserted">
                                            <span aria-live="polite" className="ng-star-inserted">
                                                <span className="show-for-sr">You're on page </span>
                                                <span>1</span>
                                            </span>
                                        </li>
                                        <li className="ng-star-inserted">
                                            <a
                                                tabIndex={1}
                                                onclick="getSubUsersList(1,true,null)"
                                                className="ng-star-inserted"
                                            >
                                                <span className="show-for-sr">You're on page </span>
                                                <span>2</span>
                                            </a>
                                        </li>
                                        <li className="ng-star-inserted">
                                            <a
                                                tabIndex={2}
                                                onclick="getSubUsersList(2,true,null)"
                                                className="ng-star-inserted"
                                            >
                                                <span className="show-for-sr">You're on page </span>
                                                <span>3</span>
                                            </a>
                                        </li>
                                        <li className="ng-star-inserted">
                                            <a
                                                tabIndex={3}
                                                onclick="getSubUsersList(3,true,null)"
                                                className="ng-star-inserted"
                                            >
                                                <span className="show-for-sr">You're on page </span>
                                                <span>4</span>
                                            </a>
                                        </li>
                                        <li className="ng-star-inserted">
                                            <a
                                                tabIndex={4}
                                                onclick="getSubUsersList(4,true,null)"
                                                className="ng-star-inserted"
                                            >
                                                <span className="show-for-sr">You're on page </span>
                                                <span>5</span>
                                            </a>
                                        </li>
                                        <li className="ng-star-inserted">
                                            <a
                                                tabIndex={5}
                                                onclick="getSubUsersList(5,true,null)"
                                                className="ng-star-inserted"
                                            >
                                                <span className="show-for-sr">You're on page </span>
                                                <span>6</span>
                                            </a>
                                        </li>
                                        <li className="ng-star-inserted">
                                            <a
                                                tabIndex={6}
                                                onclick="getSubUsersList(6,true,null)"
                                                className="ng-star-inserted"
                                            >
                                                <span className="show-for-sr">You're on page </span>
                                                <span>7</span>
                                            </a>
                                        </li>
                                        <li className="ng-star-inserted">
                                            <a
                                                tabIndex={7}
                                                onclick="getSubUsersList(7,true,null)"
                                                className="ng-star-inserted"
                                            >
                                                <span className="show-for-sr">You're on page </span>
                                                <span>8</span>
                                            </a>
                                        </li>
                                        <li className="ng-star-inserted">
                                            <a
                                                tabIndex={8}
                                                onclick="getSubUsersList(8,true,null)"
                                                className="ng-star-inserted"
                                            >
                                                <span className="show-for-sr">You're on page </span>
                                                <span>9</span>
                                            </a>
                                        </li>
                                        <li className="pagination-next  ng-star-inserted">
                                            <a
                                                tabIndex={1}
                                                onclick="getSubUsersList(1,true,null)"
                                                className="ng-star-inserted"
                                            >
                                                Next <span className="show-for-sr">page</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </pagination-template>
                        </pagination-controls> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </>
    )
}

export default CompletedMatch