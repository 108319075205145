import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import Header from './Header';
import Moment from 'react-moment';
import 'moment-timezone';
import Cookies from 'js-cookie';


function MatchResult() {

    const userN = Cookies.get('userName');


    const [eventRunners, setEventRunner] = useState([]);
    const [allData, setAllData] = useState([])
    const [selectedWinnerMO, setSelectedWinnerMO] = useState("");
    const [selectedWinnerBM, setSelectedWinnerBM] = useState("");
    const [successfulResponses, setSuccessfulResponses] = useState([]);
    const [sportSelect, setSportSelect] = useState(0)
    const [matchSelectList, setMatchSelectList] = useState([])
    const [matchSelect, setMatchSelect] = useState(0);
    const [isLoading, setIsLoading] = useState(false);



    // Fetch data for tables and initialize tablesData state
    const fetchData = async () => {
        try {
            setIsLoading(true);
            const resp = await fetch(`https://resultapi.s2bet.in/api/resultPanel/sporteventByRunner`);
            const respData = await resp.json();

            // const winnerList = await fetch(`https://resultapi.s2bet.in/api/resultPanel/getWinnerList`)
            // const winnerData = await winnerList.json()

            console.log('Runners list : ', respData.data)

            // console.log("winner list is : ", winnerData.data)


            if (respData.data.length > 0) {

                setAllData(respData.data)

                if (sportSelect != 0) {
                    const filterBySport = respData.data.filter(item => item.eid == sportSelect)
                    setEventRunner(filterBySport);
                    const filterEventName = [...new Set(filterBySport.map(item => item.series))];
                    setMatchSelectList(filterEventName);

                    console.log("Series name is : ", filterEventName)

                } else {
                    setEventRunner(respData.data);
                    const filterEventName = [...new Set(respData.data.map(item => item.series))];
                    setMatchSelectList(filterEventName);
                }

                // if (winnerData.data.length > 0) {

                //     const updatedData = respData.data.map(event => {
                //         const winnerEvent = winnerData.data.find(winner => winner.eventId == event.gameId);
                //         if (winnerEvent) {
                //             return winnerEvent; // Replace entire data object
                //         }
                //         return event; // Return original event if not found
                //     });
                //     // setEventRunner(updatedData);

                //     console.log("Updated data : ", updatedData)

                //     setEventRunner(updatedData)

                // } else {

                //     setEventRunner(respData.data)
                // }
            }

        } catch (err) {
            console.error("Error in fetching Inplay api .. ", err)
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = async (eventId, eventName, sport, winnerMO, winnerBM) => {
        // Here, you can use the data received from the columns
        console.log('EventId : ', eventId);
        console.log("Event Name:", eventName);
        console.log("Sport:", sport);
        console.log("BM", winnerBM)
        console.log("MO", winnerMO)

        if (winnerMO != '' || winnerBM != '') {

            // Initialize an object to store the winners and their selection IDs
            const winners = {};

            // Split the strings and extract the winner and selection ID for MatchOdds
            const matchOddsParts = winnerMO.split(', ');
            const matchOddsWinner = matchOddsParts[0];
            const matchOddsSid = matchOddsParts[1];

            // Split the strings and extract the winner and selection ID for BookMaker
            const bookMakerParts = winnerBM.split(', ');
            const bookMakerWinner = bookMakerParts[0];
            const bookMakerSid = bookMakerParts[1];

            // Store the winners and their selection IDs in the object
            winners.Matchodds = { winner: matchOddsWinner, sid: matchOddsSid };
            winners.BookMaker = { winner: bookMakerWinner, sid: bookMakerSid };

            // Log the object (optional)
            console.log("Winners Object:", winners);

            const reqObject = {
                userN: userN,
                eventId: eventId,
                eventName: eventName,
                sportId: sport,
                winnerName: winners.Matchodds.winner || winners.BookMaker.winner, // Use the winner from either source
                matchOddSid: winners.Matchodds.sid,
                mWinner: winners.Matchodds.winner,
                bookMakerSid: winners.BookMaker.sid,
                bWinner: winners.BookMaker.winner
            };

            console.log("Requested object is : ", reqObject);

            // if (reqObject.winnerName != '' && reqObject.matchOddSid !== undefined && reqObject.bookMakerSid !== undefined){

            if (matchOddsWinner != '' && bookMakerWinner != '') {

                try {
                    setIsLoading(true);
                    const resApi = await fetch('https://resultapi.s2bet.in/api/resultPanel/saveWinner', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(reqObject)
                    });

                    const response = await resApi.json();

                    if (response.isSuccess) {
                        console.log(response.message);
                        fetchData();
                        toast.success(`${response.message}`, {
                            autoClose: 1000,
                            theme: "colored",
                        });
                        setSelectedWinnerBM('')
                        setSelectedWinnerMO('')
                        setSuccessfulResponses(prevState => [...prevState, eventId]); // Add eventId to successfulResponses
                    } else {
                        console.log(response.message);
                        toast.error(`${response.message}`, {
                            autoClose: 1500,
                            theme: "colored",
                        });
                    }
                } catch (err) {
                    console.error("Error during inserting at frontend side ..! ", err);
                    toast.error(`Not Inserted..!`, {
                        autoClose: 1500,
                        theme: "colored",
                    });
                } finally {
                    setIsLoading(false);
                }
            } else {
                toast.error(`Please Select Runner`, {
                    autoClose: 1500,
                    theme: "colored",
                });
            }


        } else {
            toast.error(`Please Select Both Runner`, {
                autoClose: 1500,
                theme: "colored",
            });
        }

    };

    const handleSportChange = (e) => {
        console.log('select sport is : ', e.target.value)
        setSportSelect(e.target.value)
    }

    const handleMatchSelect = (e) => {
        console.log('Match Select is : ', e.target.value)
        setMatchSelect(e.target.value)

        if (e.target.value !== undefined || e.target.value !== 0) {

            const filterMatch = allData.filter(item => item.series == e.target.value)
            setEventRunner(filterMatch)
        }

        if (e.target.value == undefined || e.target.value == 0) {

            setEventRunner(allData)
        }

        // const filterByMatch = fancyList.filter
    }


    useEffect(() => {

        fetchData();
    }, [sportSelect]);



    return (
        <>

            <style
                dangerouslySetInnerHTML={{
                    __html:
                        "\n     .success-row {\n    transition: background-color 0.5s ease;\n    background-color: #f8d7da;\n} \n  "
                }}
            />
            {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
            <main role="main" className="pb-3">

                <div className="inner-pages banking">
                    <div className="container-fluid">
                        <div className="page-title" style={{ fontSize: 16 }}>
                            <h6 className="my-2">Match Result Update</h6>
                        </div>
                        <form noValidate="" className="ng-untouched ng-pristine ng-valid mb-3">
                            <div className="row marketdiv">
                                <div className="col-lg-3">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Sport Name</label>
                                            <select
                                                id="sportOption"
                                                name="sportId"
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                                onChange={handleSportChange}
                                            >
                                                <option value={0}>All</option>
                                                <option value={1} className="ng-star-inserted">
                                                    Soccer
                                                </option>
                                                <option value={2} className="ng-star-inserted">
                                                    Tennis
                                                </option>
                                                {/* <option value={3} className="ng-star-inserted">
                                                    SBO RNG Games
                                                </option> */}
                                                <option value={4} className="ng-star-inserted">
                                                    Cricket
                                                </option>
                                                {/* <option value={5} className="ng-star-inserted">
                                                    Virtual Sports
                                                </option>
                                                <option value={7} className="ng-star-inserted">
                                                    Casino
                                                </option>
                                                <option value={9} className="ng-star-inserted">
                                                    Seamless Game Provider
                                                </option>
                                                <option value={85} className="ng-star-inserted">
                                                    Election
                                                </option> */}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Series Name</label>
                                            <select
                                                name="matchId"
                                                id="matchId"
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                                onChange={handleMatchSelect}
                                            >
                                                <option value={0}>All</option>
                                                {matchSelectList.length > 0 && matchSelectList.map((item, index) => (
                                                    <option value={item} key={index}>{item}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* <div class="text-end mt-2"> */}
                        {/* <button type="submit" class="btn btnblk btn-sm w-auto ms-1">Auto Result Declare</button> */}
                        {/* </div> */}
                        <div className="profile-section mt-3 unmatech-section market-setting">
                            <div id="marketList">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>Match Name</th>
                                            <th>Series Name</th>
                                            <th>Match Date</th>
                                            <th>Sport Name</th>
                                            <th>Event Id</th>
                                            <th className="text-end">Match Odds</th>
                                            <th className="text-end">BookMaker</th>
                                            <th className="text-end">Declare Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {eventRunners.length > 0 && eventRunners.map((item, index) => {
                                            return (
                                                <tr className={item.winnerName ? 'success-row' : (successfulResponses.includes(item.gameId) ? 'success-row' : 'rows')}
                                                    key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.eventName}</td>
                                                    <td>{item.series}</td>
                                                    <td><Moment format='D/MM/Y HH:mm:ss'>{item.eventDate}</Moment></td>
                                                    {/* Sport Name */}
                                                    <td className="form-to">
                                                        <span className="form">{item.sportId || item.eid == 1 ? " Soccer" : item.sportId || item.eid == 2 ? "Tennis" : item.sportId || item.eid == 4 ? "Cricket" : item.sportId || item.eid}</span>
                                                    </td>
                                                    <td>{item.gameId || item.eventId}</td>

                                                    {item.winnerName ? (
                                                        <>
                                                            {/* MatchOdds Select */}
                                                            <td className="text-end">
                                                                <div>
                                                                    <select
                                                                        className="form-select"
                                                                        id="selectionRunnerDropdown_10"
                                                                        disabled
                                                                    >
                                                                        <option value={`${item.winnerName}, ${item.matchoddsSId}`}> {item.matchOddsSid !== null ? item.winnerName : 'Select'}</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            {/* BookMaker Select */}
                                                            <td className="text-end">
                                                                <div>
                                                                    <select
                                                                        className="form-select"
                                                                        id="selectionRunnerDropdown_10"
                                                                        disabled
                                                                    >
                                                                        <option value={`${item.winnerName}, ${item.bookMakerSId}`}>{item.bookMakerSId !== null ? item.winnerName : 'Select'}</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            {/* Buttton */}
                                                            <td className="text-end">
                                                                <button
                                                                    type="button"
                                                                    disabled
                                                                    className="btn btnblk btn-sm w-auto ms-1"
                                                                >
                                                                    Declare
                                                                </button>
                                                            </td>

                                                        </>
                                                    ) : (
                                                        <>
                                                            {/* MatchOdds Select */}
                                                            <td className="text-end">
                                                                <div>
                                                                    <select
                                                                        className="form-select"
                                                                        id={`MO_${index}`} disabled={(item.runnersMO?.length || 0) <= 0 || successfulResponses.includes(item.gameId)}
                                                                    >
                                                                        <option value=''>Select Winner Team</option>
                                                                        {(item.runnersMO || []).map((MO, idx) => (
                                                                            <option key={idx} value={`${MO.runnerName}, ${MO.selectionId}`}>{MO.runnerName}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            {/* BookMaker Select */}
                                                            <td className="text-end">
                                                                <div>
                                                                    <select
                                                                        className="form-select"
                                                                        id={`BM_${index}`} disabled={(item.runnersBM?.length || 0) <= 0 || successfulResponses.includes(item.gameId)}
                                                                    >
                                                                        <option value=''>Select Winner Team</option>
                                                                        {(item.runnersBM || []).map((BM, idx) => (
                                                                            <option key={idx} value={`${BM.runnerName}, ${BM.selectionId}`}>{BM.runnerName}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            {/* Buttton */}
                                                            <td className="text-end">
                                                                <button
                                                                    type="button"
                                                                    disabled={successfulResponses.includes(item.gameId)}
                                                                    className="btn btnblk btn-sm w-auto ms-1"
                                                                    onClick={() => {
                                                                        const MOElement = document.getElementById(`MO_${index}`);
                                                                        const BMElement = document.getElementById(`BM_${index}`);
                                                                        const MOValue = MOElement.disabled ? "disabled" : MOElement.value;
                                                                        const BMValue = BMElement.disabled ? "disabled" : BMElement.value;
                                                                        handleSave(item.gameId, item.eventName, item.eid, MOValue, BMValue);
                                                                    }}
                                                                >
                                                                    Declare
                                                                </button>
                                                            </td>
                                                        </>
                                                    )
                                                    }
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {/* <pagination-controls className="text-center">
                                    <pagination-template>
                                        <nav role="navigation" aria-label="Pagination">
                                            <ul className="ngx-pagination ng-star-inserted">
                                                <li className="pagination-previous disabled ng-star-inserted">
                                                    <span aria-disabled="true" className="ng-star-inserted">
                                                        Previous <span className="show-for-sr">page</span>
                                                    </span>
                                                </li>
                                                <li className="current ng-star-inserted">
                                                    <span aria-live="polite" className="ng-star-inserted">
                                                        <span className="show-for-sr">You're on page </span>
                                                        <span>1</span>
                                                    </span>
                                                </li>
                                                <li className="ng-star-inserted">
                                                    <a
                                                        tabIndex={1}
                                                        onclick="getSubUsersList(1,true,null)"
                                                        className="ng-star-inserted"
                                                    >
                                                        <span className="show-for-sr">You're on page </span>
                                                        <span>2</span>
                                                    </a>
                                                </li>
                                                <li className="ng-star-inserted">
                                                    <a
                                                        tabIndex={2}
                                                        onclick="getSubUsersList(2,true,null)"
                                                        className="ng-star-inserted"
                                                    >
                                                        <span className="show-for-sr">You're on page </span>
                                                        <span>3</span>
                                                    </a>
                                                </li>
                                                <li className="ng-star-inserted">
                                                    <a
                                                        tabIndex={3}
                                                        onclick="getSubUsersList(3,true,null)"
                                                        className="ng-star-inserted"
                                                    >
                                                        <span className="show-for-sr">You're on page </span>
                                                        <span>4</span>
                                                    </a>
                                                </li>
                                                <li className="ng-star-inserted">
                                                    <a
                                                        tabIndex={4}
                                                        onclick="getSubUsersList(4,true,null)"
                                                        className="ng-star-inserted"
                                                    >
                                                        <span className="show-for-sr">You're on page </span>
                                                        <span>5</span>
                                                    </a>
                                                </li>
                                                <li className="ng-star-inserted">
                                                    <a
                                                        tabIndex={5}
                                                        onclick="getSubUsersList(5,true,null)"
                                                        className="ng-star-inserted"
                                                    >
                                                        <span className="show-for-sr">You're on page </span>
                                                        <span>6</span>
                                                    </a>
                                                </li>
                                                <li className="ng-star-inserted">
                                                    <a
                                                        tabIndex={6}
                                                        onclick="getSubUsersList(6,true,null)"
                                                        className="ng-star-inserted"
                                                    >
                                                        <span className="show-for-sr">You're on page </span>
                                                        <span>7</span>
                                                    </a>
                                                </li>
                                                <li className="ng-star-inserted">
                                                    <a
                                                        tabIndex={7}
                                                        onclick="getSubUsersList(7,true,null)"
                                                        className="ng-star-inserted"
                                                    >
                                                        <span className="show-for-sr">You're on page </span>
                                                        <span>8</span>
                                                    </a>
                                                </li>
                                                <li className="ng-star-inserted">
                                                    <a
                                                        tabIndex={8}
                                                        onclick="getSubUsersList(8,true,null)"
                                                        className="ng-star-inserted"
                                                    >
                                                        <span className="show-for-sr">You're on page </span>
                                                        <span>9</span>
                                                    </a>
                                                </li>
                                                <li className="pagination-next  ng-star-inserted">
                                                    <a
                                                        tabIndex={1}
                                                        onclick="getSubUsersList(1,true,null)"
                                                        className="ng-star-inserted"
                                                    >
                                                        Next <span className="show-for-sr">page</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </pagination-template>
                                </pagination-controls> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </>
    )
}

export default MatchResult