import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import Header from './Header';
import Moment from 'react-moment';
import 'moment-timezone';
import Cookies from 'js-cookie';

function ManagerList() {
    const userN = Cookies.get('userName');

    const [data, setData] = useState([]);
    const [clickedFname, setClickedFname] = useState('');
    const [clickedUserN, setClickedUserN] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [disableValu, setDisableValue] = useState(2)


    // Fetch data for tables and initialize tablesData state
    const fetchData = async () => {
        try {
            const resp = await fetch(`https://resultapi.s2bet.in/api/resultpanel/getallactiveusersList`);
            const respData = await resp.json();

            console.log('Runners list : ', respData.data)


            if (respData.data.length > 0) {
                setData(respData.data)
            }

        } catch (err) {
            console.error("Error in fetching Inplay api .. ", err)
        }
    };

    const handleSetting = async (user, fname, closeUser) => {

        setClickedFname(fname)
        setClickedUserN(user)

        closeUser == 0 ? setIsActive(true) : setIsActive(false)
    };

    const handleDisable = async (value) => {
        setDisableValue(value)
        console.log('value is : ', value)
        if (value == 1) {
            const addOpen = document.getElementById('suspendBtn')
            addOpen.classList.add('open')
        }
        if (value == 0) {
            const addOpen = document.getElementById('activeBtn')
            addOpen.classList.add('open')
        }
    }

    const handleChange = async () => {
        if (disableValu != 2) {
            try {
                const resp = await fetch(`https://resultapi.s2bet.in/api/resultpanel/updateCloseUser`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        user: clickedUserN,
                        value: disableValu
                    })
                });
                const respData = await resp.json();

                if (respData.isSuccess) {
                    toast.success(respData.message, {
                        autoClose: 1000,
                        theme: "colored",
                    });
                    setClickedFname('');
                    setClickedUserN('');
                    setDisableValue(2);

                    // Close the modal after successful update
                    const modalClose = document.getElementById('changeStatusModal');
                    modalClose.classList.remove('show');
                    modalClose.classList.add('hide');
                    modalClose.style.display = 'none';
                    fetchData();
                    const suspendbtn = document.getElementById('suspendBtn')
                    suspendbtn.classList.remove('open')
                    const activebtn = document.getElementById('activeBtn')
                    activebtn.classList.remove('open')

                    window.location.reload();
                } else {
                    toast.error(respData.message || 'Failed to Update User', {
                        autoClose: 1500,
                        theme: "colored",
                    });
                }

            }
            catch (err) {
                toast.error(`Error in Updating`, {
                    autoClose: 1500,
                    theme: "colored",
                });

                console.log('error: ', err);
            }
        } else {
            toast.error(`Please refresh tab..`, {
                autoClose: 1500,
                theme: "colored",
            });
        }

    }


    useEffect(() => {

        fetchData();
    }, []);



    return (
        <>

            <style
                dangerouslySetInnerHTML={{
                    __html:
                        "\n     .success-row {\n    transition: background-color 0.5s ease;\n    background-color: #f8d7da;\n} \n  "
                }}
            />
            <main role="main" className="pb-3">
                <div className="inner-pages banking">
                    <div className="container-fluid">
                        <div className="page-title" style={{ fontSize: 16 }}>
                            <h6 className="my-2">Manager List</h6>
                        </div>
                        <form noValidate="" className="ng-untouched ng-pristine ng-valid mb-3">
                            <div className="row marketdiv">
                                <div className="col-lg-3">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Status</label>
                                            <select
                                                id="sportOption"
                                                name="sportId"
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                            >
                                                <option value={0}>All</option>
                                                <option value={1} className="ng-star-inserted">
                                                    Active
                                                </option>
                                                <option value={2} className="ng-star-inserted">
                                                    Disable
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* <div class="text-end mt-2"> */}
                        {/* <button type="submit" class="btn btnblk btn-sm w-auto ms-1">Auto Result Declare</button> */}
                        {/* </div> */}
                        <div className="profile-section mt-3 unmatech-section market-setting">
                            <div id="marketList">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>Manager Name</th>
                                            <th>User ID</th>
                                            <th>Status</th>
                                            <th className="text-end">Account Setting</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr className="ng-star-inserted">
                                                <td>{index + 1}</td>
                                                <td>{item.FullName}</td>
                                                <td>{item.UserName}</td>
                                                <td>
                                                    <a href="javascript:void(0);" className={item.CloseUser == 0 ? "activebtn" : "suspendbtn"}>
                                                        {item.CloseUser == 0 ? 'Active' : 'Suspend'}
                                                    </a>{" "}
                                                </td>
                                                <td className="text-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        data-toggle="modal"
                                                        data-target="#changeStatusModal"
                                                        onClick={(e) => { e.preventDefault(); handleSetting(item.UserName, item.FullName, item.CloseUser) }}
                                                    >
                                                        Setting
                                                    </button>
                                                </td>
                                                {/* Modal specific to each row */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div
                                    style={{
                                        margin: 20,
                                        display: "flex",
                                        gap: 10,
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <button style={{ padding: "5px 10px", background: "white" }}>
                                        Previous
                                    </button>
                                    <button style={{ padding: "5px 10px", background: "white" }}>
                                        1
                                    </button>
                                    <button style={{ padding: "5px 10px", background: "white" }}>
                                        2
                                    </button>
                                    <button style={{ padding: "5px 10px", background: "white" }}>
                                        3
                                    </button>
                                    <button
                                        style={{
                                            padding: "5px 10px",
                                            background: "black",
                                            color: "wheat"
                                        }}
                                    >
                                        4
                                    </button>
                                    <button
                                        style={{ padding: "5px 10px", background: "white" }}
                                        disabled=""
                                    >
                                        Next
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="changeStatusModal"
                    tabIndex={-1}
                    aria-labelledby="ChangeStatusLabel"
                    className="modal fade changepassword-model show"
                    aria-modal="true"
                    role="dialog"
                    style={{ display: "none" }}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 id="ChangeStatusLabel" className="modal-title">
                                    Change Status
                                </h6>
                                {/* <button
                      type="button" data-bs-dismiss="modal" aria-label="Close"
                      class="btn-close"><i class="bi bi-x">X</i></button> */}
                                <button type="button" className="close" data-dismiss="modal" onClick={(() => setClickedFname(''))}>
                                    X
                                </button>
                            </div>
                            <div className="modal-body change-status">
                                <div className="change-st-box">
                                    <div className="chnage-st-name d-flex justify-content-between align-items-center">
                                        <p className="mb-0">
                                            <span className="account-name-specail-txt">M</span>
                                            <b>{clickedFname}</b>
                                        </p>
                                        <a href="javascript:void(0);" className={isActive ? "activebtn" : "suspendbtn"}>
                                            {isActive ? 'Active' : 'Suspend'}
                                        </a>
                                    </div>
                                    <div className="status-button-group status_but">
                                        <input type="hidden" name="UserId" id="statusUserId" />
                                        <input type="hidden" name="Status" id="statusStatus" />
                                        <a
                                            className="status-button but_active"
                                            id="activeBtn"
                                            href="#active"
                                            data-status="locked"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (!isActive) { // Check if isActive is false
                                                    handleDisable(0); // Call handleDisable(0) only if isActive is false
                                                }
                                            }}
                                        >
                                            <div className={isActive ? 'disabled' : 'status-active'}>
                                                <div className="sts-icon">
                                                    <span className="icon">
                                                        <i className="fa fa-check-circle-o" aria-hidden="true" />
                                                    </span>
                                                </div>{" "}
                                                Active
                                            </div>
                                        </a>
                                        <a
                                            className="status-button but_suspend"
                                            id="suspendBtn"
                                            href="#active"
                                            data-status="suspend"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (isActive) { // Check if isActive is false
                                                    handleDisable(1); // Call handleDisable(0) only if isActive is false
                                                }
                                            }}
                                        >
                                            <div className={isActive ? 'status-disbled' : 'disabled'} >
                                                <div className="sts-icon">
                                                    <span className="icon">
                                                        <i className="fa fa-ban" aria-hidden="true" />
                                                    </span>
                                                </div>{" "}
                                                Suspend
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <dl className="form_list d-flex mt-3">
                                    <dd className="w-100 text-center" onClick={handleChange}>
                                        <a
                                            id="changePasswordBtn1"
                                            title="Change"
                                            className="btn btn2 px-4"
                                        >
                                            Change
                                        </a>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="editManagerModal"
                    tabIndex={-1}
                    aria-labelledby="ChangeStatusLabel"
                    className="modal fade changepassword-model show"
                    aria-modal="true"
                    role="dialog"
                    style={{ display: "none" }}
                >
                    <div className="modal-dialog match-setting-model">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 id="AddAdminLabel" className="modal-title">
                                    Match Setting
                                </h6>
                                <button type="button" className="close" data-dismiss="modal">
                                    <i className="fa fa-times close-icon" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <form noValidate="" className="">
                                    <div className="row">
                                        <div className="col-sm-12 pe-2">
                                            <div className="form-group">
                                                <label htmlFor="scorekey">Full Name:</label>
                                                <input
                                                    type="text"
                                                    id="scorekey"
                                                    formcontrolname="score_key"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 pe-2">
                                            <div className="form-group">
                                                <label htmlFor="scorekey">UserName:</label>
                                                <input
                                                    type="text"
                                                    id="scorekey"
                                                    formcontrolname="score_key"
                                                    className="form-control"
                                                    readOnly=""
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 pe-2">
                                            <div className="form-group">
                                                <label htmlFor="scorekey">Password:</label>
                                                <input
                                                    type="number"
                                                    id="scorekey"
                                                    formcontrolname="matchMax"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        {/* <div class="col-sm-12 pe-2"> */}
                                        {/* <div class="form-group"> */}
                                        {/* <label for="scorekey">Confirm Password:</label> */}
                                        {/* <input type="number" id="scorekey" formcontrolname="matchMin" class="form-control"> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                </form>
                                <div className="modal-footer">
                                    <button className="btn btn-danger">Cancel</button>
                                    <button className="btn btn-success">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default ManagerList