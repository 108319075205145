import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { toast } from 'react-toastify';

function FancyResult() {
    const [fancyList, setFancyList] = useState([]);
    const [allData, setAllData] = useState([]);
    const [sportSelect, setSportSelect] = useState(4);
    const [matchSelectList, setMatchSelectList] = useState([]);
    const [matchSelect, setMatchSelect] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const fetchAllMatchNames = async () => {
        const res = await fetch('https://resultapi.s2bet.in/api/resultPanel/fetchAllMatchNames');
        const resData = await res.json();

        if (resData.isSuccess) {
            setMatchSelectList(resData.data);
        }
    };

    // const fetchFancy = async () => {
    //     const res = await fetch(`https://resultapi.s2bet.in/api/resultPanel/fetchFancylist`)
    //     const resData = await res.json()

    //     if (resData.isSuccess) {
    //         console.log("Fancy data is : ", resData.data)
    //         setAllData(resData.data)

    //         // Filter the data to include only unique eventId
    //         const uniqueEventData = resData.data.reduce((acc, current) => {
    //             const eventId = current.eventId;
    //             if (!acc[eventId]) {
    //                 acc[eventId] = current;
    //             }
    //             return acc;
    //         }, {});

    //         // Convert the object back to an array
    //         const uniqueFancyList = Object.values(uniqueEventData);
    //         setMatchSelectList(uniqueFancyList);

    //         if (sportSelect == 0 || sportSelect == 4) {
    //             setFancyList(resData.data)

    //         } else {
    //             setFancyList([]);
    //         }
    //     }
    // }

    const fetchFancy = async () => {
        try {
            setIsLoading(true);
            const res = await fetch(`https://resultapi.s2bet.in/api/resultPanel/fetchFancylistByFilter?matchFilter=${matchSelect}&searchTerm=${encodeURIComponent(searchTerm)}&page=${currentPage}&totalPage=${perPage}`);
            const resData = await res.json();

            if (resData.isSuccess) {
                setAllData(resData.data);
                setTotalRows(resData.totalRows);

                if (sportSelect === 0 || sportSelect === 4) {
                    setFancyList(resData.data);
                } else {
                    setFancyList([]);
                }
            }
        } catch (error) {
            console.log('Error While fetching fancy list...', error)
        } finally {
            setIsLoading(false);
        }

    };

    const handleUpdate = async (eventId, sid, id) => {
        const sessionDocId = document.getElementById(id).value;

        if (sessionDocId === '') {
            toast.error('Value Not Added!', {
                autoClose: 1000,
                theme: "colored",
            });
            return;
        }

        try {
            setIsLoading(true);
            const resp = await fetch('https://resultapi.s2bet.in/api/resultPanel/sendFancyResult', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    eventId,
                    sid,
                    result: sessionDocId
                })
            });

            const respData = await resp.json();

            if (respData.isSuccess) {
                toast.success(respData.message, {
                    autoClose: 1000,
                    theme: "colored",
                });
                fetchFancy();
            } else {
                toast.error(respData.message, {
                    autoClose: 1000,
                    theme: "colored",
                });
            }
        } catch (err) {
            console.error('Error while updating winner in fancy', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSportChange = (e) => {
        setSportSelect(e.target.value);
    };

    const handleMatchSelect = (e) => {
        setMatchSelect(e.target.value);

        if (e.target.value !== undefined || e.target.value !== 0) {
            const filterMatch = allData.filter(item => item.eventId == e.target.value);
            setFancyList(filterMatch);
        }
        if (e.target.value === undefined || e.target.value === 0) {
            setFancyList(allData);
        }
    };

    const searchBtn = () => {
        if (searchTerm != '') {
            fetchFancy();
        }
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchFancy();
    };

    useEffect(() => {
        fetchAllMatchNames();
    }, []);

    useEffect(() => {
        fetchFancy();
    }, [currentPage, matchSelect]);

    return (
        <>
            <style>
                {`
                .pagination-row {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 15px;
                    }
                    .pagination-row .paginate_button {
                        margin: 0 5px;
                        padding: 5px 10px;
                        border: 1px solid #dee2e6;
                        color: black;
                        cursor: pointer;
                        background-color: #fff;
                        transition: background-color 0.3s, color 0.3s;
                        }
                        .pagination-row .paginate_button.current {
                            background-color: rgb(255, 182, 0);
                            color: #fff;
                            }
                            .pagination-row .paginate_button:hover {
                                background-color: rgb(255, 182, 0);
                                color: #fff;
                                }
                                .search-btn {
                                    margin-left: 10px;
                                    padding: 5px 15px;
                                    background-color: rgb(255, 182, 0);
                                    border: none;
                                    color: white;
                                    cursor: pointer;
                                    transition: background-color 0.3s, color 0.3s;
                                    }
                                    .search-btn:hover {
                                        background-color: #e6a800;
                                        }
                                        `}
            </style>
            {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
            <main role="main" className="pb-3">
                <div className="inner-pages banking">
                    <div className="container-fluid">
                        <div className="page-title" style={{ fontSize: 16 }}>
                            <h6 className="my-2">Fancy Result Update</h6>
                        </div>
                        <form noValidate="" className="ng-untouched ng-pristine ng-valid mb-3">
                            <div className="row marketdiv">
                                <div className="col-lg-4">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Sports Name</label>
                                            <select
                                                id="sportOption"
                                                name="sportId"
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                                onChange={handleSportChange}
                                            >
                                                <option value={4}>All</option>
                                                <option value={4} className="ng-star-inserted" disabled>Cricket</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Match Name</label>
                                            <select
                                                name="matchId"
                                                id="matchId"
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                                onChange={handleMatchSelect}
                                            >
                                                <option value={0}>All</option>
                                                {matchSelectList.length > 0 && matchSelectList.map((item, index) => (
                                                    <option value={item.eventId} key={index}>{item.eventName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group status-filter position-relative d-flex">
                                        <div>
                                            <label className="my-1 mr-2">Runner Name</label>
                                            <input
                                                type="search"
                                                name="searchTerm"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                id="searchBet"
                                                maxLength={100}
                                                size={50}
                                                className="form-control"
                                                placeholder="Search"
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="btnblk"
                                            style={{
                                                padding: '2%',
                                                width: 'auto',
                                                marginTop: 'auto',
                                                marginLeft: '2%'
                                            }}
                                            onClick={() => searchBtn()}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="profile-section mt-3 unmatech-section market-setting">
                            <div id="fancyList">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>SERIAL NO.</th>
                                            <th>MATCH NAME</th>
                                            <th>MATCH DATE</th>
                                            <th>RUNNER NAME</th>
                                            <th>RESULT</th>
                                            <th>SAVE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fancyList.length > 0 && fancyList.map((item, index) => (
                                            <tr className={item.status === 'Close' ? "ng-star-inserted success-row" : "ng-star-inserted"} key={index}>
                                                <td>{(currentPage - 1) * perPage + index + 1}</td>
                                                <td>{item.eventName}</td>
                                                <td><Moment format='D/MM/Y HH:mm:ss'>{item.eventDate}</Moment></td>
                                                <td>{item.runnerName}</td>
                                                <td className="formwidth">
                                                    <div className="form-inline d-flex">
                                                        <input
                                                            type="text"
                                                            placeholder=""
                                                            value={item.result}
                                                            className="form-control"
                                                            id={`result_${index}`}
                                                            disabled={item.status === 'Close'}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        id=""
                                                        className="btn btnblk btn-sm w-auto ms-1"
                                                        onClick={(e) => { e.preventDefault(); handleUpdate(item.eventId, item.runnerSid, `result_${index}`) }}
                                                        disabled={item.status === 'Close'}
                                                    >
                                                        Update
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <p id="items">Showing {(currentPage - 1) * perPage + 1} to {Math.min(currentPage * perPage, totalRows)} of Entries {totalRows}</p>
                                <div className="pagination-row dataTables_paginate paging_simple_numbers">
                                    {currentPage > 1 && (
                                        <button
                                            className="paginate_button"
                                            onClick={() => paginate(currentPage - 1)}
                                        >
                                            Previous
                                        </button>
                                    )}
                                    {Array.from({ length: Math.ceil(totalRows / perPage) }, (_, i) => i + 1)
                                        .filter(page =>
                                            page === 1 ||
                                            page === Math.ceil(totalRows / perPage) ||
                                            (page >= currentPage - 1 && page <= currentPage + 1)
                                        )
                                        .map(page => (
                                            <button
                                                key={page}
                                                className={`paginate_button ${currentPage === page ? 'current' : ''}`}
                                                onClick={() => paginate(page)}
                                            >
                                                {page}
                                            </button>
                                        ))}
                                    {currentPage < Math.ceil(totalRows / perPage) && (
                                        <button
                                            className="paginate_button"
                                            onClick={() => paginate(currentPage + 1)}
                                        >
                                            Next
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </main >
        </>
    );
}

export default FancyResult;
