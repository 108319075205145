import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';

function AddManager() {

    const [fullName, setFullName] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [data, setData] = useState([]);


    // Fetch data for tables and initialize tablesData state
    const fetchData = async () => {
        try {
            const resp = await fetch(`https://resultapi.s2bet.in/api/resultpanel/getallactiveusersList`);
            const respData = await resp.json();

            console.log('Runners list : ', respData.data)


            if (respData.data.length > 0) {
                setData(respData.data)
            }

        } catch (err) {
            console.error("Error in fetching Inplay api .. ", err)
        }
    };


    const onChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;


        if (name === "password") {
            setPassword(value)
        }
        if (name === "fullname") {
            setFullName(value)
        }
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();

        // Check if any field is empty
        if (!fullName || !userName || !password) {
            toast.error('Please fill all fields', {
                autoClose: 1500,
                theme: "colored",
            });
            return;
        }

        try {
            const res = await fetch("https://resultapi.s2bet.in/api/resultPanel/addUser", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fullName,
                    userName,
                    password,
                })
            });

            const responseData = await res.json();

            if (responseData.isSuccess) {
                toast.success(responseData.message, {
                    autoClose: 1000,
                    theme: "colored",
                });
                fetchData();
                setUserName('')
                setFullName('')
                setPassword('')
                const errorIdElement = document.getElementById('error_id2');
                errorIdElement.innerText = '';
            } else {
                toast.error(responseData.message || 'Failed to add user', {
                    autoClose: 1500,
                    theme: "colored",
                });
            }
        } catch (error) {
            toast.error(`Error: ${error.message}`, {
                autoClose: 1500,
                theme: "colored",
            });
            console.log('error: ', error);
        }
    };

    const handleBlur = (e) => {

        let name = e.target.name;
        let value = e.target.value;
        if (name === "username") {
            setUserName(value)
        }

        const inputElement = document.getElementById('scorekey1');
        let enteredUserId = inputElement.value;

        // Remove leading and trailing whitespace
        enteredUserId = enteredUserId.trim();

        // Check if the entered value is empty or contains only spaces
        if (enteredUserId == '' || /^\s+$/.test(enteredUserId)) {
            // Display an error message
            const errorIdElement = document.getElementById('error_id2');
            errorIdElement.innerText = 'User ID cannot be empty';
            errorIdElement.style.color = 'red';
            // setTimeout(() => {
            //     errorIdElement.innerText = '';
            // }, 3000)
            // Disable the submit button
            document.getElementById('addUser').disabled = true;
            return; // Exit the function early
        }

        // If the entered value meets the length criteria
        if (enteredUserId.length >= 4 && enteredUserId.length <= 15) {
            // Replace special characters except underscore
            const sanitizedValue = enteredUserId.replace(/[^\w\s]/g, '');
            // Update the input value
            inputElement.value = sanitizedValue;



            if (data && Array.isArray(data)) {

                console.log("Asll users in modeal : ", data)
                const userExists = data.some(user => user.UserName == sanitizedValue);
                console.log(sanitizedValue)
                const errorIdElement = document.getElementById('error_id2');

                if (userExists) {
                    // Display an error message in red
                    errorIdElement.innerText = 'User ID already exists';
                    errorIdElement.style.color = 'red';
                    // Disable the submit button
                    document.getElementById('addUser').disabled = true;
                } else {


                    // Clear the error message
                    // Display a success message in green
                    errorIdElement.innerText = 'User ID is unique';
                    errorIdElement.style.color = 'green';
                    // setTimeout(() => {
                    //     errorIdElement.innerText = '';
                    // }, 3000)
                    // errorIdElement.innerText = '';
                    // Enable the submit button
                    document.getElementById('addUser').disabled = false;
                }
            } else {
                console.error("Error: 'usersData' is undefined or has an unexpected structure");
            }
        } else {
            // Display an error message if the length is not within the specified range
            const errorIdElement = document.getElementById('error_id2');
            errorIdElement.innerText = 'User ID must be between 4 and 15 characters long';
            errorIdElement.style.color = 'red';
            // Disable the submit button
            document.getElementById('addUser').disabled = true;
        }
    };

    useEffect(() => {
        fetchData();
    }, [])




    return (
        <>
            <main role="main" className="pb-3">
                <div className="inner-pages banking">
                    <div className="container-fluid">
                        <div className="page-title" style={{ fontSize: 16 }}></div>
                        <div className="modal-dialog match-setting-model">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 id="AddAdminLabel" className="modal-title">
                                        Add Manager
                                    </h6>
                                </div>
                                <div className="modal-body">
                                    <form noValidate="" className="">
                                        <div className="row">
                                            <div className="col-sm-12 pe-2">
                                                <div className="form-group">
                                                    <label htmlFor="scorekey">Full Name</label>
                                                    <input
                                                        type="text"
                                                        id="scorekey"
                                                        formcontrolname="score_key"
                                                        className="form-control"
                                                        name='fullname'
                                                        onChange={onChangeHandler}
                                                        value={fullName}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 pe-2">
                                                <div className="form-group">
                                                    <label htmlFor="scorekey">
                                                        UserName {" "}
                                                        <span
                                                            style={{
                                                                display: "inline",
                                                                fontSize: 12,
                                                                color: "green"
                                                            }}
                                                            id="error_id2"
                                                        />
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="scorekey1"
                                                        formcontrolname="score_key"
                                                        className="form-control"
                                                        name='username'
                                                        onChange={handleBlur}
                                                        value={userName}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 pe-2">
                                                <div className="form-group">
                                                    <label htmlFor="scorekey">Password:</label>
                                                    <input
                                                        type="password"
                                                        id="scorekey"
                                                        formcontrolname="matchMax"
                                                        className="form-control"
                                                        name='password'
                                                        onChange={onChangeHandler}
                                                        value={password}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div class="col-sm-12 pe-2"> */}
                                            {/* <div class="form-group"> */}
                                            {/* <label for="scorekey">Confirm Password:</label> */}
                                            {/* <input type="number" id="scorekey" formcontrolname="matchMin" class="form-control"> */}
                                            {/* </div> */}
                                            {/* </div> */}
                                        </div>
                                    </form>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger">Cancel</button>
                                        <button id='addUser' className="btn btn-success" type='button' onClick={onSubmitForm}>Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-section mt-3 unmatech-section"></div>
                </div>
            </main>
        </>
    )
}

export default AddManager