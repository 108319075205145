import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

function Login() {

    let navigate = useNavigate();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [ValidationCode, setValidationCode] = useState("");
    const [ip, setIp] = useState("0");
    const [BrowerInfo, setBrowerInfo] = useState("0");
    const [code, setCode] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [roleId, setRoleId] = useState('');

    const [userId, setUserId] = useState();

    const [fullName, setFullName] = useState('');


    const onChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === "username") {
            setUserName(value)
        }
        if (name === "password") {
            setPassword(value)
        }
        if (name === "validationcode") {
            setValidationCode(value)
        }
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();


        if (ValidationCode !== code) {
            toast.error(`Incorrect Validation Code!`, {
                autoClose: 1500,
                theme: "colored",
            });
            codeValidation();
            return;
        }


        try {

            let res = await fetch("https://resultapi.s2bet.in/api/resultpanel/auth/login", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: userName,
                    password: password,
                    userIP: ip,
                    geoLocationn: BrowerInfo
                })
            });


            let responseData = await res.json();

            if (responseData.isSuccess) {
                setIsLoggedIn(true)
                setRoleId(responseData.roleId);
                setFullName(responseData.FullName);
                console.log(responseData.Id);
                setUserId(responseData.Id);

                Cookies.set('userName', responseData.data.UserName);
                Cookies.set('id', responseData.data.Id);
                Cookies.set('fullName', responseData.data.FullName);
                Cookies.set('RoleId', responseData.data.roleId);

                Cookies.set('isLoggedIn', true)

                toast.success(responseData.message, {
                    autoClose: 1000,
                    theme: "colored",
                });
                navigate('/')
            }
            else {
                toast.error(`${responseData.message}`, {
                    autoClose: 1500,
                    theme: "colored",
                });
                codeValidation();
            }


        } catch (error) {
            toast.error(`Error : ${error}`, {
                autoClose: 1500,
                theme: "colored",
            });
            codeValidation();
            console.log('error  : ', error)
        }
    }

    const codeValidation = () => {
        let number1 = Math.floor(Math.random() * 10);
        let number2 = Math.floor(Math.random() * 10);
        let number3 = Math.floor(Math.random() * 10);
        let number4 = Math.floor(Math.random() * 10);
        setCode(String(number1) + String(number2) + String(number3) + String(number4));
    }

    useEffect(() => {
        codeValidation();
    }, []);

    useEffect(() => {
        ; (async () => {
            let res = await fetch(`https://api.ipify.org/?format=json`);
            let resData = await res.json();
            // console.log(resData);
            setIp(resData.ip);

        })()

    }, []);

    useEffect(() => {
        (async () => {
            console.log(`ip of this brower is : ${ip}`);
            let res = await fetch(`http://ip-api.com/json/${ip}`);
            let resData = await res.json();
            let city = await resData.city;
            let region = await resData.regionName;
            let country = await resData.country;
            let zip = await resData.zip;
            // console.log(resData);
            setBrowerInfo(`${city}/${region}/${country}/${zip}`);
            // console.log(BrowerInfo);
        })();
    }, [ip]);



    return (
        <>
            <div className="loginpanle-page">
                <div className="container-adminpanle">
                    <div className="login-sec">
                        <div className="container">
                            <div className="login-inner">
                                <div className="login-left">
                                    <img className="dekstop-img" src="images/bg-login_wrap.png" alt="" />
                                    <img className="responsive-img" src="images/KV-pic.png" alt="" />
                                    <div className="mobile-logo">
                                        <h1>SKYEXCHANGE</h1>
                                    </div>
                                </div>
                                <div className="login-right">
                                    <p className="font-size20">Agent login</p>
                                    {/* <img src="images/verifycode.gr" alt="" /> */}
                                    <form onSubmit={onSubmitForm}>
                                        <ul>
                                            <li>
                                                <input type="text" name='username' onChange={onChangeHandler} value={userName} placeholder="username" />
                                            </li>
                                            <li>
                                                <input type="password" name='password' onChange={onChangeHandler} value={password} placeholder="password" />
                                            </li>
                                            <li className="verify-coad" style={{ display: "flex", alignItems: "center" }}>
                                                <input type="text" name='validationcode' onChange={onChangeHandler} value={ValidationCode} placeholder="Validation Code" />
                                                <span style={{ background: "white", padding: "11px", fontSize: "1.2rem", fontWeight: "bold" }}>{code}</span>
                                            </li>
                                            <li>
                                                <button>
                                                    Login
                                                    <img src="images/login-icon.svg" alt="" />
                                                </button>
                                            </li>
                                        </ul>
                                    </form>
                                    <div className="responsive-support-sec">
                                        <ul>
                                            <li>
                                                <div className="responsive-support-btn">
                                                    <div className="support-btn">
                                                        <ul>
                                                            <li>
                                                                <img src="images/mike-phone.svg" alt="" />
                                                            </li>
                                                            <li>
                                                                <a href="#">Customer support1</a>
                                                            </li>
                                                            <li className="support-border">
                                                                <a href="#">support2</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="support-btn">
                                                        <ul>
                                                            <li>
                                                                <img src="images/phone-icon.svg" alt="" />
                                                            </li>
                                                            <li>
                                                                <a href="#">+918335896365</a>
                                                            </li>
                                                            <li className="support-border">
                                                                <a href="#">+918335896365</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="border-line" />
                                            </li>
                                            <li>
                                                <div className="support-btn-sec social-sec">
                                                    <div className="support-btn">
                                                        <ul>
                                                            <li>
                                                                <img src="images/sky-icon.svg" alt="" />
                                                            </li>
                                                            <li>
                                                                <a href="#">Skype</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="support-btn">
                                                        <ul>
                                                            <li>
                                                                <img src="images/shear-icon.svg" alt="" />
                                                            </li>
                                                            <li>
                                                                <a href="#">Email</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="support-btn">
                                                        <ul>
                                                            <li>
                                                                <img src="images/insta-icon.svg" alt="" />
                                                            </li>
                                                            <li>
                                                                <a href="#">insta</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Customer-support-sec">
                        <div className="container">
                            <div className="Customer-support-inner desktop-support-sec">
                                <ul>
                                    <li>
                                        <div className="support-btn-sec">
                                            <div className="support-btn">
                                                <ul>
                                                    <li>
                                                        <img src="images/mike-phone.svg" alt="" />
                                                    </li>
                                                    <li>
                                                        <a href="#">Customer support1</a>
                                                    </li>
                                                    <li className="support-border">
                                                        <a href="#">support2</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="support-btn">
                                                <ul>
                                                    <li>
                                                        <img src="images/phone-icon.svg" alt="" />
                                                    </li>
                                                    <li>
                                                        <a href="#">+918335896365</a>
                                                    </li>
                                                    <li className="support-border">
                                                        <a href="#">+918335896365</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="border-line" />
                                    </li>
                                    <li>
                                        <div className="support-btn-sec social-sec">
                                            <div className="support-btn">
                                                <ul>
                                                    <li>
                                                        <img src="images/sky-icon.svg" alt="" />
                                                    </li>
                                                    <li>
                                                        <a className="dekstop-text" href="#">
                                                            skyexchangeofficial
                                                        </a>
                                                        <a className="responsive-text" href="#">
                                                            Skype
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="support-btn">
                                                <ul>
                                                    <li>
                                                        <img src="images/shear-icon.svg" alt="" />
                                                    </li>
                                                    <li>
                                                        <a className="dekstop-text" href="#">
                                                            info@skyexchange.com
                                                        </a>
                                                        <a className="responsive-text" href="#">
                                                            Email
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="support-btn">
                                                <ul>
                                                    <li>
                                                        <img src="images/insta-icon.svg" alt="" />
                                                    </li>
                                                    <li>
                                                        <a className="dekstop-text" href="#">
                                                            officialskyexchange
                                                        </a>
                                                        <a className="responsive-text" href="#">
                                                            insta
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Powered by */}
                    <div className="Powered-by">
                        <div className="container">
                            <div className="Powered-by-inner">
                                <ul>
                                    <li>
                                        <h3>
                                            <span>Powered by</span>
                                            <img src="images/logo-betfair.png" alt="" />
                                        </h3>
                                    </li>
                                    <li>
                                        <iframe
                                            src="https://licensing.gaming-curacao.com/validator/?lh=7a83475c9e54450a218a18bd28e33fad&template=seal"
                                            width={150}
                                            height={50}
                                            style={{ border: "none" }}
                                        />
                                    </li>
                                    <li>
                                        <p>
                                            <a href="#">Sky Infotech Limited</a> is licensed and regulated by
                                            Government of Curacao under license no 365/JAZ Sub-License
                                            GLH-OCCHKTW0707072017.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="browsers-sec">
                        <div className="container">
                            <div className="browsers-inner">
                                <img src="images/icon-browser-W.png" alt="" />
                                <p>
                                    Our website works best in the newest and last prior version of these
                                    browsers:
                                    <br />
                                    Google Chrome. Firefox
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login