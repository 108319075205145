import { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Component/Login';
import Dashboard from './Component/Dashboard';
import MatchResult from './Component/MatchResult';
import FancyResult from './Component/FancyResult';
import ChangePassword from './Component/ChangePassword';
import PrivateRoutes from './utils/PrivateRoutes';
import RouteLayout from './Layouts/RouteLayout';
import AddManager from './Component/AddManager';
import ManagerList from './Component/ManagerList';
import CompletedMatch from './Component/CompletedMatch';
import CompleteFancy from './Component/CompleteFancy';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if user information is present in session storage
    const userName = Cookies.get('userName');
    const userId = Cookies.get('id');

    if (userName && userId) {
      setIsLoggedIn(true);
      Cookies.set('isLoggedIn', true);
    } else {
      setIsLoggedIn(false);
      Cookies.set('isLoggedIn', false);
    }
  }, [isLoggedIn]);

  return (

    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={!isLoggedIn ? <Login /> : <Navigate to={'/'} />} />
          <Route element={<PrivateRoutes />}>
            <Route path='/' element={<RouteLayout />} >
              <Route index element={<Dashboard />} />
              <Route path='/matchResult' element={<MatchResult />} />
              <Route path='/fancyResult' element={<FancyResult />} />
              <Route path='/changePassword' element={<ChangePassword />} />
              <Route path='/addManager' element={<AddManager />} />
              <Route path='/managerList' element={<ManagerList />} />
              <Route path='/completeMatch' element={<CompletedMatch />} />
              <Route path='/completeFancy' element={<CompleteFancy />} />

            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>

  );
}

export default App;
